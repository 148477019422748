import React from "react";
import "./homepage.style.css";
import DirectoryMenu from "../../components/directory/directory.component";

function Homepage() {
  return (
    <div className="homepage">
      <DirectoryMenu />
    </div>
  );
}

export default Homepage;
